/* EXTRA SMALL DEVICES (PHONES, 600PX AND DOWN) */
.ow {
  background-color: #efefef;
}

@media only screen and (max-width: 600px) {
  .card {
    width: 50vw;
    /* height: 50vh; */
    margin: 0 auto;
    margin-bottom: 1rem;
    /* box-shadow: 0px 0px 20px 0 rgba(0, 0, 0, 0.5); */
  }
  .col-12,
  .col-sm-6 {
    /* box-shadow: 0px 0px 20px 0 rgba(0, 0, 0, 0.5); */
    background-color: white;
  }
  .chart {
    margin: 0 auto;
    margin-top: 2rem;
    /* height: 40vh; */
    width: 40vw;
  }
  .card-title {
    padding-top: 1rem;
    font-size: 18px;
    font-weight: 700;
    color: var(--primary-color);
  }
  .pill-container {
    display: flex;
  }
  .pills {
    width: 15px;
    height: 15px;
    margin: 1rem 1rem 0rem 2rem;
  }
  .pill-label {
    margin-top: 12px;
    font-weight: 500;
  }
}

/*  SMALL DEVICES (PORTRAIT TABLETS AND LARGE PHONES, 600PX AND UP) */
@media only screen and (min-width: 600px) {
  .col-12,
  .col-sm-6 {
    /* box-shadow: 0px 0px 20px 0 rgba(0, 0, 0, 0.5); */
    /* height: 40vh; */
    background-color: white;
  }
  .chart {
    /* margin-top: 2rem; */
  }
  .card-title {
    padding-top: 1rem;
    font-size: 18px;
    font-weight: 700;
    color: var(--primary-color);
  }
  .card {
    width: 30vw;
    /* height: 30vh; */
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  .pill-container {
    display: flex;
  }
  .pills {
    width: 15px;
    height: 15px;
    margin: 1rem 1rem 0rem 2rem;
  }
  .pill-label {
    margin-top: 12px;
    font-weight: 700;
  }
}
/* MEDIUM DEVICES (LANDSCAPE TABLETS, 768PX AND UP) */
@media only screen and (min-width: 768px) {
}
/* LARGE DEVICES (LAPTOPS/DESKTOPS, 992PX AND UP) */
@media only screen and (min-width: 992px) {
}
/* EXTRA LARGE DEVICES (LARGE LAPTOPS AND DESKTOPS, 1200PX AND UP) */
@media only screen and (min-width: 1200px) {
  .col-12,
  .col-sm-6 {
    /* height: 40vh; */
  }
  .card {
    /* height: 15vh; */
    /* width: 100vw; */
    align-items: space-between;
    display: flex;
    margin-bottom: 0rem;
  }
  .pill-box {
    margin-top: 4rem;
  }
}
