.formHeading {
    font-size: 24px!important;
    color: #ebebeb!important;
    margin-bottom: 20px;
    font-weight: 500!important;
}
.CustomForm button:hover {
    background-color: var(--theme-color);
    color: #fff;
}

.CustomForm button {
    background-color: #fff;
    margin-top: 20px;
    color: #152C56;
    border: none;

    font-weight: 500;
    font-size: 18px;
    padding: 10px 20px!important;
}