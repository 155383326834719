.mission_card {
    background: #F9F9F9;
    box-shadow: 0px 7px 24.8px -4px rgb(0 0 0 / 25%);
    border-radius: 21px;
    padding: 38px 97px 38px 35px;
    margin-bottom: 80px;
}

.mission_card h3 {
    font-size: 34px;
    font-weight: bold;
    margin-bottom: 16px;
}

.mission_card p {
    color: #282828;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
}

@media screen and (max-width: 767px) {
    .mission_card {
        padding: 30px;
        margin-bottom: 32px;
        border-radius: 12px;

    }

    .mission {
        padding: 0px 24px;
        margin-bottom: 60px;
    }

    .mission_card h3 {
        font-size: 24px;
        line-height: 1.3;
    }

}