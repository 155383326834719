/* .PravacyContainer{
    margin: 80px auto;
} */

.Container {
  overflow-x: hidden !important;
}

.subHeading {
  font-size: 32px;
  font-weight: 400;
  line-height: 1.2;
  color: #ededed;
  margin-bottom: 24px;
}

.heading {
  font-size: 56px;
  font-weight: 600;
  line-height: 1.3;
  color: #fff;
  margin-bottom: 20px;
}

.description {
  font-size: 16px;
  line-height: 1.7;

  color: #f6f6f6;
  width: 60%;
}


.wrapperContainer {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 80px 0px;

}

.listItem {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  width: calc(100% / 2 - 16px);
  height: max-content;
}

.icon {
  margin-right: 15px;
  color: #152C56 !important;
  font-size: 24px;
}

.text1 {
  margin: 0;
  color: #333;
  font-size: 16px;
  line-height: 1.6;
}

@media screen and (max-width: 767px) {
  .subHeading {
    font-size: 24px;
    line-height: 1.3;
  }
  
  .heading {
    font-size: 32px;
  }
  .mainWrapper{
    padding: 60px 24px;
    /* height: fit-content!important; */
    height: fit-content!important;
  }
  .description{
    width: auto!important;
  }
  .wrapperContainer{
    padding: 60px 0px;
   }
   .listItem{
    width: 100%!important;
   }
}
@media screen and (max-width: 575px) {
  .wrapperContainer{
    padding: 60px 24px;
   }
}
.listItem:nth-last-child(1)
{
  margin-bottom: 0px;
}