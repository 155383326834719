.OurServicesWrapper {
    background-color: #111111;
    padding: 80px 0px;
}

.HeadingColor {
    color: #FFFFFF;
    margin-bottom: 60px;
}

.cardsWrapperContainer {
    /* width: 100%; */
    /* max-width: 1140px; */
    margin: 0 auto;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.cardWraper {
    width: calc(100% / 4 - 16px);
    height: max-content;
    /* margin-bottom: 24px; */
    /* cursor: pointer; */
    border-radius: 8px;
    /* background-color: #FFFFFF; */
    position: relative;
}

.thumbnail {
    object-fit: cover;
    width: 100%;
    height: 240px;
    border-radius: 8px 8px 0px 16px;
    /* z-index: 1; */
    position: relative;
}

.textCard {
    margin-top: -20px;
    padding: 24px 20px 32px 20px;
    position: relative;
    background-color: #FFFFFF;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.cardTitle {
    color: #282828;
    font-size: 19px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 16px;
    line-height: normal;

}

.cardDescription {
    color: #282828;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    line-height: normal;
}

@media screen and (max-width: 767px) {
   .OurServicesWrapper{
    padding: 60px 24px;
   }
   .cardWraper{
    width: 100%;
    margin-bottom: 16px;

   }
   .m_0 {
    margin: 0px ;
    padding: 0px;
   }
   }