.mb40 {
    padding-bottom: 40px;
}

.Title {
    color: #282828;
    font-size: 32px;
    font-weight: 800;
    margin-bottom: 16px;
}

.Details {
    font-size: 18px;
    color: #343434;
}

.mb80 {
    margin-bottom: 80px;
}

@media screen and (max-width: 767px) {
    .mb40 {
        padding: 0px 24px 60px 24px;
    }

    .mb80 {
        padding: 0px 24px 0px 24px;
        margin-bottom: 60px;
    }

    .Title {
        font-size: 24px;
        line-height: 1.2;
    }

    .Details {
        font-size: 16px;
        line-height: 1.5;
    }
}