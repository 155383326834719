.HeadingColor {
    color: #282828;
    margin-bottom: 80px !important;
}

.fullScreenBg {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../../assets/carousel/bg.jpg');
    background-size: cover;
    background-position: center;
    height: auto;
    /* height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center; */
}

/* .sliderItem {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 500px;
} */
.customSlide {
    overflow-x: hidden !important;
}

.price {
    font-size: 32px;
    font-weight: 600;
    line-height: 1;
    color: #fff;
    margin-bottom: 32px;
}

.carname {
    font-size: 56px;
    font-weight: 600;
    line-height: 1.3;
    color: #fff;
    margin-bottom: 24px;
}

.rightSide {
    padding-top: 45px;
    padding-bottom: 45px;
    padding-left: 46px;
    padding-right: 47px;
    background: rgba(255, 255, 255, 0.21);
    border-radius: 5px;
    overflow: hidden;
    backdrop-filter: blur(4px);
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 36px;
}

.type {
    font-size: 13px;
    color: #fff;
    font-weight: 500;
    line-height: 12px;
    margin-top: 16px;
}

.typeValue {
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    line-height: 18px;
    margin-top: 8px;
}

.slick-dots{
    bottom: 50px!important;
    /* left: 0%; */
}
@media screen and (max-width: 767px) {
    .HeadingColor{
    margin-bottom: 60px!important;
    }
    .carname{
        font-size: 32px;
        line-height: normal;
    }
    .price{
        font-size: 28px;
    }
    .rightSide{
        padding: 24px;
    }
    .customSlide .section_wrap{
        padding: 0px 24px;
    }
  /* .section_wrap{
    flex-wrap: wrap;
    flex-flow: column;
    align-items: start!important;
    padding: 0px 24px;
  } */
     }