.mainWrapper {
    margin-top: 80px;
    margin-bottom: 80px;
}

.faqWraper {
    max-height: 600px;
    /* Adjust based on your needs */
    overflow-y: scroll;
    padding: 0px 16px;
    /* background-color: #fff; Add any necessary background styling */
}


/* Scrollbar Track */
.faqWraper::-webkit-scrollbar {
    width: 4px;
    /* Width of the scrollbar */
    border-radius: 10px;

}

/* Scrollbar Handle */
.faqWraper::-webkit-scrollbar-thumb {
    background-color: #DE4317;
    /* Color of the scrollbar handle */
    border-radius: 10px;
    /* Rounded corners */
}

/* Scrollbar Handle Hover */
.faqWraper::-webkit-scrollbar-thumb:hover {
    background-color: #b63916;
    /* Color when hovering over the scrollbar handle */
    cursor: pointer;
}

/* Scrollbar Track */
.faqWraper::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Color of the scrollbar track */
    border-radius: 10px;
    /* Rounded corners for the track */
}

.faqCard {
    width: 100%;
    /* height: 187px; */
    height: fit-content;
    position: relative;
    background: #F2F2F2;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 16px;
    /* Spacing between cards */
    padding: 20px;
}

.faqCard:nth-last-child(1) {
    margin-bottom: 0px;
}

.faqTitle {
    /* position: absolute; */
    /* left: 28px; */
    /* top: 40px; */
    color: #152C56;
    font-size: 24px;
    font-weight: 600;
    text-transform: capitalize;
    /* line-height: 46.69px; */
    /* word-wrap: break-word; */
    margin-bottom: 16px;
}

.faqDescription {
    width: 90%;
    height: fit-content;
    padding-left: 32px;
    color: #282828;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 1.6;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    /* Number of lines to show */
}

.faqDescription.expanded {
    white-space: normal;
    overflow: visible;
    text-overflow: clip;
    -webkit-line-clamp: unset;
    /* Remove the line clamp when expanded */
}


.moreBtn {
    color: #282828;
    font-size: 17px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    text-transform: capitalize;
    cursor: pointer;
    display: inline-flex;
    /* margin-top: 10px; */
    margin-left: 32px;
}

.faqWraperRight {
    padding-left: 32px !important;
}

.subheadingFaq {
    color: #152C56;
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    text-transform: capitalize;
    line-height: 1.5;
    margin-bottom: 16px;
}

.headingFaq {
    /* text-align: justify; */
    color: #32353A;
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 52px;
}

.aboutTeamHeading {
    /* width: 174.40px;
    height: 21.60px; */
    /* text-align: justify; */
    color: #333333;
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
    line-height: 1.4;
    margin-bottom: 16px;
}

.aboutTeamDescription {
    color: #282828;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 1.4;
}

.aboutDetailsWrapper {
    gap: 32px;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 767px) {
    .faqTitle{
        line-height: 1.5;
        font-size: 20px;
    }
    .faqWraper {
        padding: 0px !important;
    }

    .mainWrapper {
        padding: 0px 24px;
        margin: 60px 0px;
    }

    .faqWraperRight {
        padding-left: 0px!important;
        padding-right: 0px!important;
        padding-top: 60px;
    }
}