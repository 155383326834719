.key_information .heading {
text-align: left!important;
margin-top: 20px;
font-size: 22px!important;
color: #152C56;
font-weight: 600;
}
.listing_key {
    column-gap: 20px;
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    margin-top: 21px;
}
.listing_title{
    color: #152C56;
    flex-shrink: 0;
    font: inherit;
    font-weight: 500;
    margin-bottom: 0px;
    text-align: center;
}
.listing_title img{
    padding-bottom: 6px!important;
}
.listing_information_text{
    color: inherit;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    margin-top: -8px;
}
.listing_information{
    align-items: center;
    border: 1px solid #dedede;
    color: var(--genesisauto-header-font-color);
    display: flex;
    flex-direction: column;
    font-size: 12px;
    gap: 10px;
    justify-content: space-between;
    margin-bottom: 22px;
    padding: 15px 0px;
    
}