.other_cars{
    background-color: #fff;
    padding: 50px 0px;
}
.heading h3{
    color: #0d2048;
font-size: 2.5rem;
font-weight: 600;
padding-bottom: 20px;
}
/* sold out badge */
.sold_out span{
    background-color: red;
    color: #fff;
    padding: 5px 15px;
    border-radius: 4px;
    
}
.sold_out{
    margin-top: -20px;
    position: relative;
    z-index: 5555;
}
.other_cars p{
    font-size: 16px;
    margin-bottom: 0px;
    text-align: left;
    padding: 0px;
    color: #000;
}
.other_car_card {
    border: 1px solid #e5e5e5;
    border-radius: 0px;
    background-color: #fafafa;
   

}

.other_car_card img{
    margin: auto;
    width: 100%;
    text-align: center;
    border-radius: 12px 12px 0px 0px;
}
.other_card_body{
    padding: 20px 0px;
}
.car_title {
    font-size: 18px!important;
    font-weight: 500!important;
    letter-spacing: 0;
    margin-top: 10px;
    color: #0d2048;
   
    text-align:center!important;
}

.similar_car {
    background: #fafafa;
}
.similar_car .card-body{
padding: 16px!important;
border-top: 1px solid #0d2048;
background-color: #0d2048;
}
.similar_car .card-body p{
color: #d4d4d4;
font-size: 14px;
}
.similar_car h5{
    color: #fff;
    margin-top: 0px;
    font-weight: 500;
    padding-top: 0px;
    font-size: 16px!important;
}

.similar_car .card{
    width: auto!important;
    text-align: left;
    border: 1px solid #0d2048;

}

.text-purple{
    color: #725687!important;
}
.info-text p{
    color: #AAAAAA;
}
.view_benifite p{
    font-size: 12px;
    color: #AAAAAA;

}
.other_car_card .icon{
    text-align: right!important;
    padding-right: 16px;
    right: 0;
    position: absolute;
    top: 6px;
}
/* intrested_sec */
.intrested_sec {
    padding: 50px 0px;
}
.filter-card-item{
    background-color: #F2F2F2;
    /* margin-bottom:24px; */
}
.filter-card-item .price {
   margin-top: -8px!important;
    color: #0D2048;
    z-index: 1;
    background: #fff;
    font-size: 12px;
    font-weight: 700;
    /* display: inline-block; */
    padding: 2px 10px;
    width: max-content;
    border-radius: 3px;
    margin-left: auto;

    position: relative;

}
    .filter-card-item .filter-card-content {
        padding: 0 16px 24px;
        text-align: left;
    }
    .filter-card-item .filter-card-content .price-btn {
        margin-top: -15px;
        z-index: 10;
    }
    .small-btn-meta {
        padding: 6px 14px;
        font-weight: 700;
        font-size: 14px;
        background: #0d2048;
        border-radius: 3px;
        color: #fff!important;
        -webkit-transition: .3s ease-in;
        transition: .3s ease-in;
    }
    .filter-card-item .filter-card-content a{
        text-decoration: none;
    }
    .filter-card-item .filter-card-content h5 {
        -webkit-transition: .3s ease-in;
        transition: .3s ease-in;
        color: #0D2048;
        font-size: 16px!important;
        text-decoration: none;
    }
    .filter-card-item .filter-card-content .meta-content {
        font-size: 14px;
        font-weight: 500;
    }
    .filter-card-item .filter-card-content .meta-content strong {
        font-weight: 700;
        color: #111010;
    }
    .filter-card-item .filter-card-content .meta-content a {
        color: #575757;
    }
    .card-feature-box {
        gap: 20px;
    }
    .card-feature-box .icon-box {
        font-size: 14px;
        font-weight: 600;
    }
    .outline-btn{
        border: 1px solid #0d2048;
    }
    .outline-btn:hover{
        background-color: #0d2048;
        color:#fff;
    }

    .model-text{
        font-size: 10px;
        color: #111010;
        font-weight: 400;
        line-height: 18px;
    }
    .brandLogo{
        width: 32px ;
        height: 32px;
        object-fit: contain;
        aspect-ratio: 3/2;
    }

    .ctaContainer{
        background-color: #f2f2f2;
        padding: 24px 32px!important;
        gap: 32px;
margin: 80px auto;
    }
    .ctaText{
        font-size: 16px;
        line-height: normal;
        color: #152C56;
        font-weight: 500;
    }
    .ctaBtnViewMore{
        font-size: 20px ;
        font-weight: 600;
        color: #fff;
        padding: 12px 24px;
        background-color: #DE4317;
        min-width: max-content;
    }

    @media screen and (max-width: 767px) {
        .ctaContainer{
            display: block!important;
            margin-top: 60px;
        }
        .ctaBtnViewMore{
            margin-top: 20px;
        }
        .filter-card-item{
            margin-left: 0px;
            margin-right: 0px;
            margin-bottom: 20px;
        }
        .other_cars{
            padding: 40px 24px;
        }
        
       }