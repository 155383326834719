.heroImage {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.subHeading {
    font-size: 20px;
    color: var(--secondary-color);
    font-weight: 500;


}

.heading {
    font-size: 64px;
    font-weight: 700;
    color: #FFF;
    line-height: default;
    margin-top: 12px;
}
.description{
    font-size: 20px ;
    font-weight: 500;
    color: #FFF;
    line-height: normal;
    margin-top: 32px;
}

.darkBg {
    background-color: #111111;
    /* align-content: center; */
    padding: 60px 150px 0px 90px;
}
@media screen and (max-width: 767px) {
   .darkBg{
     padding:60px 24px;
   }
   .heading{
    font-size: 40px;
    line-height: 1;

   }
   .description{
    font-size: 16px;
    margin-top: 16px;
   }
  }