.contact {
    padding: 80px 0px;

}

.contact h3 {
    color: #282828;
    font-size: 32px;
    font-weight: 800;
}

.contact h4 {
    color: #282828;
    font-size: 18px;
    margin-bottom: 20px
}

.card_one {
    background-color: #F2F2F2;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
}

.contact ul {
    list-style: none;

}

.free {
    margin-bottom: 20px;
}

.contact ul li {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;

}

.contact ul li svg {
    background-color: var(--theme-color);
    padding: 10px;
    vertical-align: middle;
    color: #fff;
    font-size: 16px;
    margin-right: 16px;
    width: 20px;
    height: 20px;
    border-radius: 50px;
}

.contact_card {
    background-color: #161C28;
    border-radius: 12px;
    padding: 40px;
}

.contact_form label {
    font-size: 16px;
    color: #ebebeb;
}

.contact_form input::placeholder {
    color: #ebebeb;

}

.contact_form input,
.contact_form textarea {
    border-radius: 6px;
    color: #ebebeb !important;
    box-shadow: none !important;
    padding: 10px 20px;
    background-color: transparent !important;
}

.contact_form button:hover {
    background-color: var(--theme-color);
    color: #fff;
}

.contact_form button {
    background-color: #fff;
    margin-top: 20px;
    color: #152C56;
    border: none;

    font-weight: 500;
    font-size: 18px;
    padding: 15px 50px;
}
.sectionParagraph {
    color: #fff;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 1.5;
}

@media screen and (max-width: 767px) {
    .contact {
        padding: 60px 24px;
    }
.contact_form{
    padding-left: 0px;
    padding-right: 0px;
}
    .contact h4 {
        font-size: 16px;
        margin-bottom: 16px;
    }

    .card_one {
        padding: 16px;
    }

    .contact_card {
        padding: 20px;
    }
}