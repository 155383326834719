.list_style li{
    display: block;
}

.share_icons svg:last-child{
    border: none!important;
}
.card-header , .accordion-button {
 background-color: #0d2048;
 color: #fff;
}
.accordion-item{
    border: none;
}
.spe_key{
    width: 50%;
}
.accordion-button:not(.collapsed) {
    background-color: #0d2048;
    border: none;
    color: #fff;
    box-shadow: none;
}
.accordion-button:not(.collapsed) svg {
transform: rotate(180deg);
}
.accordion-button{
box-shadow: none!important;
border-radius: 0px!important;
}

.accordion-button:not(.collapsed)::after{
    background-image: none!important;
}
.accordion-button svg{
    position: absolute;
    right: 2%;
}
.list_style:first-child{
    white-space: nowrap;
}
.specifications
{
    text-align: left;
    color: #0d2048;
    font-weight: 400;
    font-size: 18px;
    white-space: normal;
}
.specification-card
{
    background-color: #fff !important;
    color: #0d2048;
    border: none;
}
.specification-heading
{
    text-align: left;
    color: #fff;
    font-weight: bold;
}
@media screen and (max-width: 767px) {
    .spe_key{
        width: 100%;
    }
  }
