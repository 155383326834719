.submit_btn{
    font-size: 16px ;
    font-weight: 400;
    color: #fff;
    padding: 12px 24px;
    background-color: #DE4317;
    min-width: max-content;
    margin-top: 30px;
    margin-bottom: 20px;
    border: none;
}
.submit_btn:hover
{
    background-color: #0d2048;
}

form
{
    padding: 20px;
}
.form_card
{
    background-color: #161C28;
    border-radius: 10px;
}