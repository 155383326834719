
.title
{
    margin: 0px !important;
    padding: 0px !important;
}

.text
{
    color: blue;
    font-size: 12px;
    padding-left: 150px;
}
.h3
{
    padding-right: 200px !important;
}
.bmw
{
    color: #152C56!important;
    text-align: left!important;
    font-weight:700!important;
    font-size: 28px!important;
    letter-spacing: 0px;
}
.petrol
{
    font-size: 15px;
    text-align: left;

}
.all
{
    height: 150px;
    margin: 10px;
    padding: 10px;
    border-radius: 10px !important;
}
.rate
{
    text-align: left;
    font-weight: bold;
    color: #152C56;
}
.book
{
    background-color: #152C56 !important;
    width: 200px !important;
    
}
.test
{
    width: 200px !important;
    padding-left: 10px !important;
}
.specifications
{
    text-align: left;
    color: white;
    font-weight: 400;
    font-size: 18px;
}
.specification-card
{
    background-color: #0d2048 !important;
    border: none;
}
.specification-heading
{
    text-align: left;
    color: #0d2048;
    font-weight: bold;
}
/* h3 {
    font-size: 30px!important;
    color: #0d2048!important;
    font-weight: 700!important;
    text-align: center!important;
    margin-bottom: 20px!important;
} */
form{

text-align: left!important;
}

/* product page designinf */
.badge_sale{
    padding: 4px 12px!important;
    font-size: 12px!important;
    font-weight: 600!important;
    height: 26px;
    line-height: 1.5;
    border-radius: 3px!important;
}
.bg_color{
    background-color: #F2F2F2!important;
    padding: 40px 24px;
    border-radius: 0.3125rem;
}
.price{
color: #0D2048;
font-size: 28px;
font-weight: 500;
}
.widget_meta {
    font-size: 13px;
    position: relative;
    font-weight: 500;
}
.emi_calculate {
    font-size: 12px;
    color: #0D2048;
    text-decoration: none;
    color: #0D2048;
    font-weight: 500;
}
.btn_book {
    background-color: #0d2048;
    color: #fff;
    font-size: 18px;
    padding: 15px 20px;
    width: 400px;
   
}
.btn_book:hover{
    background-color: #fff;
    border: 1px solid #0d2048;
}
.share_icons{
  
}
.share{
    
}

.share_icons svg{
padding:0px 15px;
width: 20px;
height: 20px;
border-right: 1px solid #AAAAAA;
}

.testdrive_btn
{
    background-color: #FE7A36;
    color: #fff;
    font-size: 18px;
    padding: 15px 20px;
    width: 400px;
}
.testdrive_btn:hover{
        background-color: #fff;
        border: 1px solid #0d2048;
    }

    @media screen and (max-width: 767px) {
        .btn_book
        {
          width: 200px;  
        }
        .testdrive_btn
        {
           width: 200px; 
        }
        .product_price
        {
            padding: 15px;
        }
        .custom_tabs
        {
            padding: 15px!important;
        }
        .single_pro_section
        {
            padding-right: 15px!important;
        }
        .bmw
        {
            padding-left: 12px;
        }
    }